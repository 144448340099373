import {
  AreaChartOutlined,
  BellOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FilterOutlined,
  LogoutOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

export const SUB_MENU_MONITORING = [
  { label: "Medicine Room 1", path: "medicine-room-1", icon: "" },
  { label: "Medicine Room 2", path: "medicine-room-2", icon: "" },
  { label: "Medicine Room 3", path: "medicine-room-3", icon: "" },
  { label: "Medicine Room 4", path: "medicine-room-4", icon: "" },
  { label: "Warehouse", path: "warehouse", icon: "" },
  { label: "Refrigerator", path: "refrigerator", icon: "" },
];

export const MENUS_KEY = {
  MEDICINEROOM1: "medicine-room-1/:id",
  MEDICINEROOM2: "medicine-room-2/:id",
  MEDICINEROOM3: "medicine-room-3/:id",
  MEDICINEROOM4: "medicine-room-4/:id",
  WAREHOUSE: "warehouse/:id",
  REFRIGERATOR: "refrigerator/:id",
  MONITORING: "monitoring",
  OVERVIEW: "overview",
  DOWNLOAD:'download',
  DASHBOARD: "dashboard",
  DEVICE_MANAGEMENT: "device-management",
  ZONE_MANAGEMENT: "zone-management",
  ALARM: "alarm",
  NOTIFICATION: "notification",
  NOTIFICATION_SETTING: "notification-setting",
};

export const MENUS_LABEL = {
  USER_MANAGEMENT: "User management",
  CATEGORY_AND_ATTRIBUTE: "Category & Attribute",
};

export const SUB_KEY = {
  TRANSACTION: [{ key: "merchant", label: "Merchant", path: "merchant" }],
};

export const MENUS_ITEM = {
  MONITORING: {
    key: "monitoring",
    label: "Monitoring",
    level: 1,
    icon: <FilterOutlined />,
    children: [
      {
        key: "medicine-room-1",
        label: "Merchant",
        path: "medicine-room-1",
        level: 2,
      },
      {
        key: "medicine-room-2",
        label: "Consumer",
        path: "medicine-room-2",
        level: 2,
      },
      {
        key: "medicine-room-3",
        label: "Consumer",
        path: "medicine-room-3",
        level: 2,
      },
      {
        key: "medicine-room-4",
        label: "Consumer",
        path: "medicine-room-4",
        level: 2,
      },
      { key: "warehouse", label: "Consumer", path: "warehouse", level: 2 },
      {
        key: "refrigerator",
        label: "Consumer",
        path: "refrigerator",
        level: 2,
      },
    ],
  },
  OVERVIEW: {
    key: "overview",
    label: "Overview",
    level: 1,
    icon: <AreaChartOutlined />,
    children: [],
  },
  DEVICE_MANAGEMENT: {
    key: "device-management",
    label: "Device Management",
    level: 1,
    icon: <NotificationOutlined />,
  },
  ZONE_MANAGEMENT: {
    key: "zone-management",
    label: "Zone Management",
    level: 1,
    icon: <DatabaseOutlined />,
  },
  ALARM: {
    key: "alarm",
    label: "Alarm",
    level: 1,
    icon: <FileTextOutlined />,
  },
  NOTIFICATION_SETTING: {
    key: "notification-setting",
    label: `User Setting`,
    level: 1,
    icon: <BellOutlined />,
  },
  LOGOUT: {
    key: "logout",
    label: "Logout",
    level: 1,
    icon: <LogoutOutlined />,
  },
  DOWNLOAD: {
    key: "download",
    label: "Download",
    level: 1,
    icon: <DownloadOutlined />,
  },
};
