export const PATHS = {
  default: () => "/",
  overview: () => "/overview",
  policy: () => "/policy",
  monitoring: () => "/monitoring",
  medicineRoom1: () => "/medicine-room-1/:id",
  medicineRoom2: () => "/medicine-room-2/:id",
  medicineRoom3: () => "/medicine-room-3/:id",
  medicineRoom4: () => "/medicine-room-4/:id",
  warehouse: () => "/warehouse/:id",
  refrigerator: () => "/refrigerator/:id",
  login: () => "/login",
  deviceManagement: () => "/device-management",
  zoneManagement: () => "/zone-management",
  alarm: () => "/alarm",
  notificationSetting: () => "/notification-setting",
  deviceDetail: () => "/device-detail/:id",
  zoneDetail: () => "/zone-detail/:id",
  download: () => "/download"
};
