const accessToken = "admin_access_token";
const refreshToken = "admin_refresh_token";

export function hasStorageJwtToken() {
  return !!localStorage.getItem(accessToken);
}

export function removeStorageJwtToken() {
  localStorage.removeItem(accessToken);
}

export function setStorageJwtToken(token: string) {
  localStorage.setItem(accessToken, token);
}

export function getStorageJwtToken() {
  return localStorage.getItem(accessToken);
}
//

export function hasStorageRefreshToken() {
  return !!localStorage.getItem(refreshToken);
}

export function removeStorageRefreshToken() {
  localStorage.removeItem(refreshToken);
}

export function setStorageRefreshToken(token: string) {
  localStorage.setItem(refreshToken, token);
}

export function getStorageRefreshToken() {
  return localStorage.getItem(refreshToken);
}
