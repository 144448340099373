import loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import { PrivateRoute } from "./components/PrivateRoute";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(
  loadFn: (props: {}) => Promise<DefaultComponent<{}>>
) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/NotFound"));
const LoginPage = loadableWFallback(() => import("src/pages/login"));
const Overview = loadableWFallback(() => import("src/pages/overview"));
const MedicineRoom1 = loadableWFallback(
  () => import("src/pages/monitoring/medicine-room-1")
);
const MedicineRoom2 = loadableWFallback(
  () => import("src/pages/monitoring/medicine-room-2")
);
const MedicineRoom3 = loadableWFallback(
  () => import("src/pages/monitoring/medicine-room-3")
);
const MedicineRoom4 = loadableWFallback(
  () => import("src/pages/monitoring/medicine-room-4")
);
const Warehouse = loadableWFallback(
  () => import("src/pages/monitoring/warehouse")
);
const Refrigerator = loadableWFallback(
  () => import("src/pages/monitoring/refrigerator")
);
const DeviceManagementPage = loadableWFallback(
  () => import("src/pages/device-management")
);
const ZoneManagementPage = loadableWFallback(
  () => import("src/pages/zone-manage")
);

const AlarmPage = loadableWFallback(() => import("src/pages/alarm"));
const DownloadPage = loadableWFallback(() => import("src/pages/download"));

const NotificationSettingPage = loadableWFallback(
  () => import("src/pages/notification-setting")
);
const DeviceDetail = loadableWFallback(() => import("src/pages/device-detail"));
const ZoneDetail = loadableWFallback(() => import("src/pages/zone-detail"));
const PolicyPage = loadableWFallback(() => import("src/pages/policy"));

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={PATHS.overview()} />} />
      <Route
        exact
        path={PATHS.login()}
        render={() =>
          hasStorageJwtToken() ? (
            <Redirect to={PATHS.notificationSetting()} />
          ) : (
            <LoginPage />
          )
        }
      />
      <Route exact path={PATHS.policy()} render={() => <PolicyPage />} />

      <PrivateRoute exact path={PATHS.overview()} component={Overview} />
      <PrivateRoute
        exact
        path={PATHS.medicineRoom1()}
        component={MedicineRoom1}
      />
      <PrivateRoute
        exact
        path={PATHS.medicineRoom2()}
        component={MedicineRoom2}
      />
      <PrivateRoute
        exact
        path={PATHS.medicineRoom3()}
        component={MedicineRoom3}
      />
      <PrivateRoute
        exact
        path={PATHS.medicineRoom4()}
        component={MedicineRoom4}
      />
      <PrivateRoute exact path={PATHS.warehouse()} component={Warehouse} />
      <PrivateRoute
        exact
        path={PATHS.refrigerator()}
        component={Refrigerator}
      />
      <PrivateRoute
        exact
        path={PATHS.deviceManagement()}
        component={DeviceManagementPage}
      />
      <PrivateRoute
        exact
        path={PATHS.zoneManagement()}
        component={ZoneManagementPage}
      />
      <PrivateRoute exact path={PATHS.alarm()} component={AlarmPage} />
      <PrivateRoute
        exact
        path={PATHS.notificationSetting()}
        component={NotificationSettingPage}
      />
      <PrivateRoute
        exact
        path={PATHS.notificationSetting()}
        component={NotificationSettingPage}
      />
      <PrivateRoute
        exact
        path={PATHS.zoneDetail()}
        component={ZoneDetail}
      />
      <PrivateRoute
        exact
        path={PATHS.deviceDetail()}
        component={DeviceDetail}
      />
      <PrivateRoute
        exact
        path={PATHS.download()}
        component={DownloadPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
