import { ServiceBase } from "./core/service-base";
export class AuthServices extends ServiceBase {
  // Implement method call API
  login = async (params: { username: string; password: string }) => {
    return this.post("user/login", params);
  };

  createZone = (data: any) => {
    return this.post("zone-configuration", data);
  };

  getZones = (params: any) => {
    return this.get("zone-configuration", params);
  };

  getZonesDetail = (id: any , params?: any) => {
    return this.get(`/zone-configuration/${id}` , params);
  };

  deleteZone = (id: number) => {
    return this.delete("zone-configuration", id);
  };

  deleteFile = (id: number | string) => {
    return this.delete("file/delete", id);
  };

  deleteEmail = (id: number | string) => {
    return this.delete("setting/email", id);
  };

  deleteUser = (id: number | string) => {
    return this.delete("user", id);
  };

  updateZone = (id: number, data: any) => {
    return this.patch(`zone-configuration/${id}`, data);
  };

  getDevices = (params: any) => {
    return this.get("device", params);
  };

  overviewData = () => {
    return this.get("zone-configuration/over-view-report");
  };

  getDetailDevice = (id: string | number , params ?: any) => {
    return this.get(`device/${id}` , params);
  };

  getAlarms = (params: any) => {
    return this.get("alarm-record", params);
  };

  addMail = (email: string) => {
    return this.post("setting/add-email", { email });
  };

  getMails = () => {
    return this.get("setting/get-list-email");
  };

  addUser = (body: any) => {
    return this.post("user/register", body);
  };

  getUsers = (params: any) => {
    return this.get("user", params);
  };

  exportPdfDevice = (id : string|number , params?: any) => {
    return this.get(`device/${id}/export-pdf`, params);
  }

  exportExcelDevice = (id : string|number , params?: any) => {
    return this.get(`device/${id}/export-excel`, params);
  }

  uploadFile = async (
    file: File,
  ): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.axiosInstance.post("file/upload", formData);
    return response.data;
  };

  getDownload = (params: any) => {
    return this.get('file/get-list', params)
  }
}
